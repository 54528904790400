<template>
  <div>
    <div class="editTitle">工况参数</div>

    <div class="edit-main">
      <el-form
        :model="editForm"
        label-width="150px"
        :rules="rules"
        ref="editVersionForm"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="设备编号">
              {{ deviceNo }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最后更新">
              {{ 44656455465465 }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="程序版本">
              {{ 445454 }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类别" prop="type">
              <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.type"
                placeholder="请选择类别"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主臂长度/m" prop="armlength">
              <el-input
                v-model="editForm.armlength"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          
          <el-col :span="8">
            <el-form-item label="后臂长度/m" prop="reararm">
              <el-input
                v-model="editForm.reararm"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主臂宽度/m" prop="armwidth">
              <el-input
                v-model="editForm.armwidth"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最大力矩/吨" prop="maxmoment">
              <el-input
                v-model="editForm.maxmoment"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="参考零点" prop="heightzero">
              <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.heightzero"
                placeholder="请选择类别"
              >
                <el-option
                  v-for="item in heightzeroList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="轨道长度" prop="rail">
              <el-input
                v-model="editForm.rail"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最大吊重/t" prop="maxload">
              <el-input
                v-model="editForm.maxload"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="节高" prop="sectionheight">
              <el-input
                v-model="editForm.sectionheight"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="首节高度/m" prop="fristsection">
              <el-input
                v-model="editForm.fristsection"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="倍率" prop="rate">
              <el-input
                v-model="editForm.rate"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="节数" prop="sectionnum">
              <el-input
                v-model="editForm.sectionnum"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="塔机纬度" prop="latitude">
              <el-input
                v-model="editForm.latitude"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="塔机经度" prop="longitude">
              <el-input
                v-model="editForm.longitude"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="顶升爬架" prop="climbheight">
              <el-input
                v-model="editForm.climbheight"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="塔高" prop="craneheight">
              <el-input
                v-model="editForm.craneheight"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
//   import {equipType} from "../../../config/dataStatus"
//   import versionMange from "../../../api/modules/versionMange";
import basic from "../../api/modules/basic";
export default {
  name: "driverEdit",
  components: { CustomDialog },
  props: ["visible", "title", "item", "deviceId",'deviceNo'],
  data() {
    return {
      editForm: {},
      rules: {
        fristsection: [
          { required: true, message: "请输入首节高度", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类别", trigger: "change" }],
        heightzero: [
          { required: true, message: "请选择参考零点", trigger: "change" },
        ],
        armlength: [{ required: true, message: "请输入臂长", trigger: "blur" }],

        sectionheight: [
          { required: true, message: "请输入标准节高度", trigger: "blur" },
        ],
        armwidth:[
        { required: true, message: "请输入主臂宽度", trigger: "blur" },
        ],
       
        reararm: [
          { required: true, message: "请输入后臂长度", trigger: "blur" },
        ],
        sectionnum: [
          { required: true, message: "请输入标准节数量", trigger: "blur" },
        ],
        maxload: [
          { required: true, message: "请输入最大调重", trigger: "blur" },
        ],
        rate: [{ required: true, message: "请输入倍率", trigger: "blur" }],
        baseheight: [
          { required: true, message: "请输入基准平面高度差", trigger: "blur" },
        ],
        climbheight: [
          { required: true, message: "请输入顶升爬架高度", trigger: "blur" },
        ],
        craneheight: [
          { required: true, message: "请输入塔高", trigger: "blur" },
        ],
        rail: [{ required: true, message: "请输入轨道长度", trigger: "blur" }],
        maxmoment: [
          { required: true, message: "请输入最大力矩", trigger: "blur" },
        ],
        latitude: [
          { required: true, message: "请输入塔机纬度", trigger: "blur" },
        ],
        longitude: [
          { required: true, message: "请输入塔机经度", trigger: "blur" },
        ],
       
      },
      uploadUrl: config.uploadUrl,
      equipType: [],
      params: {},
      equipNo: "",
      typeList: [
        { label: "平臂式", value: "flat-top" },
        { label: "动臂式", value: "luffingjib" },
      ],
      heightzeroList: [
        { label: "顶点", value: "top" },
        { label: "底部", value: "bottom" },
      ],
      paramType:''
      // equipType: equipType
    };
  },

  mounted() {
    this.params = JSON.parse(this.item.paramContent);

    console.log("item222", this.item);
this.paramType=this.item.paramType
    if (this.params) {
      this.editForm = {
        ...this.editForm,
        ...this.params.workset,
      };
    }
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    submit() {
      this.$refs.editVersionForm.validate((valid) => {
        if (valid) {
          const obj = {
            workset: { ...this.editForm },
          };
          let params = {
            paramContent: JSON.stringify(obj),
            paramName: this.item.paramName,
            paramId: this.item.paramId,
            deviceId: this.deviceId,
            deviceParamDetailId: this.item.deviceParamDetailId,
            paramLable: this.item.paramLable,
            paramType:this.paramType
            // id:props.item.id,
          };
          basic.updateDeviceParam(params).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.$emit("done");
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
</style>
