<template>
  <div>
    <div class="editTitle">告警设置（百分比）</div>

    <div class="edit-main">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        label-width="140px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="高度向上运行减速：">
              <el-input
                class="smallInputs"
                v-model="formInline.up1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.up1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.up1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.up1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.up1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
       
          </el-col>
          <el-col :span="24">
            <el-form-item label="高度向上运行停止：">
              <el-input
                class="smallInputs"
                v-model="formInline.up2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.up2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.up2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.up2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.up2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
            
          </el-col>
          <el-col :span="24">
            <el-form-item label="风速预警：">
              <el-input
                class="smallInputs"
                v-model="formInline.wind1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.wind1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.wind1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.wind1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.wind1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="风速告警：">
              <el-input
                class="smallInputs"
                v-model="formInline.wind2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.wind2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.wind2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.wind2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.wind2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="高度向下运行减速：">
              <el-input
                class="smallInputs"
                v-model="formInline.down1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.down1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.down1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.down1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.down1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
        
          </el-col>
          <el-col :span="24">
            <el-form-item label="高度向下运行停止：">
              <el-input
                class="smallInputs"
                v-model="formInline.down2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.down2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.down2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.down2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.down2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="水平倾角预警：">
              <el-input
                class="smallInputs"
                v-model="formInline.horizontal1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.horizontal1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.horizontal1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.horizontal1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.horizontal1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            
            <el-form-item label="水平倾角告警：">
              <el-input
                class="smallInputs"
                v-model="formInline.horizontal2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.horizontal2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.horizontal2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.horizontal2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.horizontal2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="回转向左运行减速：">
              <el-input
                class="smallInputs"
                v-model="formInline.left1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.left1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.left1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.left1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.left1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
            
          </el-col>
          
          <el-col :span="24">
            <el-form-item label="回转向左运行停止：">
              <el-input
                class="smallInputs"
                v-model="formInline.left2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.left2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.left2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.left2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.left2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
           
          </el-col>
         
          <el-col :span="24">
            <el-form-item label="回转向右运行减速：">
              <el-input
                class="smallInputs"
                v-model="formInline.right1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.right1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.right1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.right1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.right1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
           
          </el-col>
         
          <el-col :span="24">
            <el-form-item label="回转向右运行停止：">
              <el-input
                class="smallInputs"
                v-model="formInline.right2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.right2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.right2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.right2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.right2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
           
          </el-col>


          <el-col :span="24"> 
            <el-form-item label="变幅向内运行减速：">
              <el-input
                class="smallInputs"
                v-model="formInline.in1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.in1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.in1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.in1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.in1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>

          <el-col :span="24"> 
            <el-form-item label="变幅向内运行停止：">
              <el-input
                class="smallInputs"
                v-model="formInline.in2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.in2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.in2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.in2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.in2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>

          <el-col :span="24"> 
            <el-form-item label="变幅向外运行减速：">
              <el-input
                class="smallInputs"
                v-model="formInline.out1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.out1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.out1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.out1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.out1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>

          <el-col :span="24"> 
            <el-form-item label="变幅向外运行停止：">
              <el-input
                class="smallInputs"
                v-model="formInline.out2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.out2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.out2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.out2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.out2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>



          <el-col :span="24">
            <el-form-item label="溜钩告警：">
              <el-input
                class="smallInputs"
                v-model="formInline.slide.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.slide.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.slide.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.slide.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.slide.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24"> 
            <el-form-item label="垂直倾角预警：">
              <el-input
                class="smallInputs"
                v-model="formInline.vertical1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.vertical1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.vertical1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.vertical1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.vertical1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24"> 
            <el-form-item label="垂直倾角告警：">
              <el-input
                class="smallInputs"
                v-model="formInline.vertical2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.vertical2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.vertical2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.vertical2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.vertical2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="碰撞预警：">
              <el-input
                class="smallInputs"
                v-model="formInline.collide1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.collide1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.collide1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.collide1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.collide1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="碰撞告警：">
              <el-input
                class="smallInputs"
                v-model="formInline.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.collide2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.collide2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.collide2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：">
              <el-switch
                v-model="formInline.collide2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="起重量超90%：">
              <el-input
                class="smallInputs"
                v-model="formInline.load1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.load1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.load1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.load1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.load1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
           
          </el-col>
        
          <el-col :span="24">
            <el-form-item label="起重量超100%：">
              <el-input
                class="smallInputs"
                v-model="formInline.load2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.load2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.load2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.load2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.load2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="起重力矩超80%：">
              <el-input
                class="smallInputs"
                v-model="formInline.moment1.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.moment1.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.moment1.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.moment1.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.moment1.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="起重力矩超90%：">
              <el-input
                class="smallInputs"
                v-model="formInline.moment2.threshold"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警级别：">
              <el-input
                class="smallInputs"
                v-model="formInline.moment2.level"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="等级：">
              <el-select v-model="formInline.moment2.compare" placeholder="请选择" class="block-select"  size="small">
                <el-option
                  v-for="item in compareList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="延迟(秒)：">
              <el-input
                class="smallInput"
                v-model="formInline.moment2.delay"
                placeholder=""
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传平台：" style="margin-right: 60px">
              <el-switch
                v-model="formInline.moment2.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
//   import {equipType} from "../../../config/dataStatus"
//   import versionMange from "../../../api/modules/versionMange";
import basic from "../../api/modules/basic";
export default {
  name: "driverEdit",
  components: { CustomDialog },
  props: ["visible", "title", "item", "deviceId"],
  data() {
    return {
      tableData: [],
      formInline: {},
      paramType: "",
      compareList: [
        { label: "小于", value: -1 },
        { label: "等于", value: 0 },
        { label: "大于", value: 1 },
      ],
    };
  },
  mounted() {
    this.params = JSON.parse(this.item.paramContent);

    console.log("params2222222222222", this.params);
    this.paramType = this.item.paramType;
    if (this.params) {
      this.formInline = {
        ...this.formInline,
        ...this.params.alarm,
      };
    }
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    submit() {
      const obj = {
        alarm: this.formInline,
      };
      let params = {
        paramContent: JSON.stringify(obj),
        paramName: this.item.paramName,
        paramId: this.item.paramId,
        deviceId: this.deviceId,
        deviceParamDetailId: this.item.deviceParamDetailId,
        paramLable: this.item.paramLable,
        paramType: this.paramType,
        // id:props.item.id,
      };
      basic.updateDeviceParam(params).then((res) => {
        this.$message({
          message: "编辑成功",
          type: "success",
        });
        this.$emit("done");
      });
    },
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
.btn {
  background-color: #008f4d;
  // margin-bottom: 10px;
  // color: #008f4d;
}
.smallInput {
  width: 50px;
}
.smallInputs {
  width: 100px;
}
.block-select{
  width: 80%;
}
</style>
