<template>
  <div>
    <div class="editTitle">通用配置</div>

    <div class="edit-main">
      <el-row :gutter="160">
        <el-form
          :model="editForm"
          label-width="150px"
          :rules="rules"
          ref="editVersionForm"
          class="demo-form-inline"
        >
          <el-col :span="12">
            <el-form-item label="激活状态：" prop="register">
              <el-switch
                v-model="editForm.register"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="心跳间隔(s)：" prop="heartinterval">
              <el-input
                v-model="editForm.heartinterval"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="锁机状态：" prop="lock">
              <el-switch
                v-model="editForm.lock"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数据上传间隔(s)：" prop="datainterval">
              <el-input
                v-model="editForm.datainterval"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备回调地址：" prop="httpaddr">
              <el-input
                v-model="editForm.httpaddr"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="24" class="marginRight">
          <el-button
            type="success"
            size="small"
            @click="travelClick"
            icon="el-icon-plus"
            class="btn"
            >新建</el-button
          >
          <div>数据上传地址：</div>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column
              prop="date"
              label="交互终端上传地址"
              align="center"
            >
            <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row"
                  placeholder="请输入交互终端上传地址"
                ></el-input>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span @click="detailItemTravel(scope.$index)" class="detail">
                  <i class="el-icon-delete"></i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24" class="marginRight">
          <el-button
            type="success"
            size="small"
            @click="travelClickTwo"
            icon="el-icon-plus"
            class="btn"
            >新建</el-button
          >
          <div>第三方协议：</div>
          <el-table :data="tableDataAgreement" border style="width: 100%">
            <el-table-column prop="name" label="名称" align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.name"
                  placeholder="请输入名称"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
              <template slot-scope="scope">
                <el-select
                  class="block-select"
                  size="small"
                  v-model="scope.row.status"
                  placeholder="请选择"
                >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span @click="detailItemTravelTwo(scope.$index)" class="detail">
                  <i class="el-icon-delete"></i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
//   import {equipType} from "../../../config/dataStatus"
//   import versionMange from "../../../api/modules/versionMange";
import basic from "../../api/modules/basic";
export default {
  name: "driverEdit",
  components: { CustomDialog },
  props: ["visible", "title" , "item", "deviceId"],
  data() {
    return {
      editForm: {
        register: "",
        heartinterval: "",
        lock: "",
        datainterval: "",
        httpaddr: "",
      },
      tableData: [],
      statusList:[
      { label: "在线", value: "0" },
        { label: "离线", value: "1" },
      ],
      tableDataAgreement:[],
      rules: {
        built: [{ required: true, message: "请输入程序标识", trigger: "blur" }],
        type: [
          { required: true, message: "请选择版本类别", trigger: "change" },
        ],
        ver: [{ required: true, message: "请输入固件版本", trigger: "blur" }],
        url: [{ required: true, message: "请选择升级包", trigger: "change" }],
        sourcefile: [
          { required: true, message: "请输入升级文件名", trigger: "blur" },
        ],
      },
      uploadUrl: config.uploadUrl,
      equipType: [],
      paramType:''
      // equipType: equipType
    };
  },
  mounted() {
    this.params = JSON.parse(this.item.paramContent);
    this.paramType=this.item.paramType
    console.log("params2", this.params);

    if (this.params) {
      this.editForm.register = this.params.generic.register;
        this.editForm.datainterval = this.params.generic.datainterval;
        this.editForm.heartinterval = this.params.generic.heartinterval;
        this.editForm.httpaddr = this.params.generic.httpaddr;
        this.editForm.lock = this.params.generic.lock;
        this.tableData =this.params.generic.serveraddr
        this.tableDataAgreement=this.params.generic.otheraddr
      // this.tableData = this.params.collide;
    }
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    submit() {
      const obj = {
       
       generic:{
         datainterval:this.editForm.datainterval,
         heartinterval:this.editForm.heartinterval,
         httpaddr:this.editForm.httpaddr,
         lock:this.editForm.lock,
         register:this.editForm.register,
         serveraddr:this.tableData,
         otheraddr:this.tableDataAgreement
       }

         }
         let params = {
            paramContent: JSON.stringify(obj),
            paramName: this.item.paramName,
            paramId: this.item.paramId,
            deviceId: this.deviceId,
            deviceParamDetailId: this.item.deviceParamDetailId,
            paramLable: this.item.paramLable,
            paramType:this.paramType
          };
          basic.updateDeviceParam(params).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.$emit("done");
          });
    },
    travelClick() {
      this.tableData.push('');
    },
    travelClickTwo(){
      this.tableDataAgreement.push({
        name: "",
        state: "",
      });
    },
    detailItemTravel(index) {
      this.tableData.splice(index, 1);
    },
    detailItemTravelTwo(index) {
      this.tableDataAgreement.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
.smallInput {
  width: 80px;
}
.btn {
  background-color: #008f4d;
  // margin-bottom: 10px;
  // color: #008f4d;
}
.marginRight {
  //   margin-right: 30px;
  //   width: 49%;
  margin-bottom: 20px;
}
</style>
