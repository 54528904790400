<template>
  <div>
    <div class="editTitle">标定参数</div>

    <div class="edit-main">
      <div class="display">
          <div class="widthBox ">
          <el-button
                type="success"
                size="small"
               @click="travelClick"
                icon="el-icon-plus"
               
                class="btn"
                >新建</el-button
              >
              <div>塔身变幅</div>
              <div>编码器行程 travel@encoder</div>
            
          <el-table :data="tableData" border style="width: 100%"  >
            <el-table-column prop="date" label="模拟量" align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.AD"
                  placeholder="请输入模拟量"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="值" align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.value"
                  placeholder="请输入值"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center"> 
                <template slot-scope="scope">
              <span
                @click="detailItemTravel(scope.$index)"
                class="detail"
              >
              <i class="el-icon-delete"></i>
            </span>
          
            </template>
            </el-table-column>
          </el-table>
        </div>
       
    
          <div class="widthBox ">
          <el-button
                type="success"
                size="small"
                @click="weightClick"
                icon="el-icon-plus"
               
                class="btn"
                >新建</el-button
              >
              <div>塔身变幅起升</div>
              <div>模拟信号重量 weight@analog</div>
            
          <el-table :data="tableDataWeight" border style="width: 100%">
            <el-table-column prop="date" label="模拟量"  align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.AD"
                  placeholder="请输入模拟量"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="值"  align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.value"
                  placeholder="请输入值"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center"> 
                <template slot-scope="scope">
              <span
                @click="detailWeight(scope.$index)"
                class="detail"
              >
              <i class="el-icon-delete"></i>
            </span>
          
            </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="display">
          <div class="widthBox ">
          <el-button
                type="success"
                size="small"
               
                icon="el-icon-plus"
                @click="heightClick"
                class="btn"
                >新建</el-button
              >
              <div>塔身变幅起升</div>
              <div>编码器高度 height@encoder</div>
            
          <el-table :data="tableDataHeight" border style="width: 100%">
            <el-table-column prop="date" label="模拟量"   align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.AD"
                  placeholder="请输入模拟量"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="值"  align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.value"
                  placeholder="请输入值"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center"> 
                <template slot-scope="scope">
              <span
                @click="detailHeight(scope.$index)"
                class="detail"
              >
              <i class="el-icon-delete"></i>
            </span>
          
            </template>
            </el-table-column>
          </el-table>
        </div>
       
       
          <div class="widthBox ">
          <el-button
                type="success"
                size="small"
               
                icon="el-icon-plus"
                @click="rotationClick"
                class="btn"
                >新建</el-button
              >
              <div>塔身</div>
              <div>编码器回转 rotation@encoder</div>
            
          <el-table :data="tableDataRotation" border style="width: 100%">
            <el-table-column prop="date" label="模拟量" align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.AD"
                  placeholder="请输入模拟量"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="值" align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.value"
                  placeholder="请输入值"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center"> 
                <template slot-scope="scope">
              <span
                @click="detailRotation(scope.$index)"
                class="detail"
              >
              <i class="el-icon-delete"></i>
            </span>
          
            </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      
          <div class="widthBox ">
          <el-button
                type="success"
                size="small"
               
                icon="el-icon-plus"
                @click="BoomClick"
                class="btn"
                >新建</el-button
              >
              <div>仰角 动臂式塔吊</div>
             
          <el-table :data="tableDataBoom" border style="width: 100%">
            <el-table-column prop="date" label="模拟量" align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.AD"
                  placeholder="请输入模拟量"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="值" align="center">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.value"
                  placeholder="请输入值"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center"> 
                <template slot-scope="scope">
              <span
                @click="detailBoom(scope.$index)"
                class="detail"
              >
              <i class="el-icon-delete"></i>
            </span>
          
            </template>
            </el-table-column>
          </el-table>
        </div>
   
      
    </div>
  </div>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
//   import {equipType} from "../../../config/dataStatus"
//   import versionMange from "../../../api/modules/versionMange";
import basic from "../../api/modules/basic";
export default {
  name: "calibrationSet",
  components: { CustomDialog },
  props: ["visible", "title",'item','deviceId'],
  data() {
    return {
        tableData:[],
        tableDataWeight:[],
        tableDataHeight:[],
        tableDataRotation:[],
        tableDataBoom:[],
        params:'',
        rate:0,
        paramType:''
    };
  },
  mounted() {
    this.params = JSON.parse(this.item.paramContent);
    this.paramType=this.item.paramType
    console.log("params", this.params);

    if (this.params) {
      
      this.rate=this.params.calibration.rate
      this.tableData= this.params.calibration.luffing
      this.tableDataWeight= this.params.calibration.load
      this.tableDataHeight= this.params.calibration.height
      this.tableDataRotation= this.params.calibration.rotation
      this.tableDataBoom= this.params.calibration.elevation
    }
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    submit() {
     
      
          const obj = {
            calibration:{
              rate:this.rate,
              load:this.tableDataWeight,
              height:this.tableDataHeight,
              luffing: this.tableData,
              rotation:  this.tableDataRotation,
              elevation:this.tableDataBoom
            }
          }
          let params = {
            paramContent: JSON.stringify(obj),
            paramName: this.item.paramName,
            paramId: this.item.paramId,
            deviceId: this.deviceId,
            deviceParamDetailId: this.item.deviceParamDetailId,
            paramLable: this.item.paramLable,
            paramType:this.paramType
            // id:props.item.id,
          };
          basic.updateDeviceParam(params).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.$emit("done");
          });
       
    },
    travelClick(){
      this.tableData.push({
        AD:'',
        value:''
      })
    },
    detailItemTravel(index){
      this.tableData.splice(index,1)
    },
    weightClick(){
      this.tableDataWeight.push({
        AD:'',
        value:''
      })
    },
    detailWeight(index){
      this.tableDataWeight.splice(index,1)
    },
    heightClick(){
      this.tableDataHeight.push({
        AD:'',
        value:''
      })
    },
    detailHeight(index){
      this.tableDataHeight.splice(index,1)
    },
    rotationClick(){
      this.tableDataRotation.push({
        AD:'',
        value:''
      })
    },
    detailRotation(index){
      this.tableDataRotation.splice(index,1)
    },
    BoomClick(){
      this.tableDataBoom.push({
        AD:'',
        value:''
      })
    },
    detailBoom(index){
      this.tableDataBoom.splice(index,1)
    }
    
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
  // display: flex;
  // justify-content: space-between;
}
.btn {
    background-color: #008f4d;
    // margin-bottom: 10px;
    // color: #008f4d;
  }
  .marginRight{
    margin-right: 10px;
    width: 49%;
    margin-bottom: 10px;
  }
  .widthBox{
  width: 45%;
  margin-bottom: 20px;
  
  
}
.display{
    display: flex;
  justify-content: space-between;
}

</style>
