<template>
    <div>
      <div class="editTitle">网络设置</div>
  
      <div class="edit-main">
        <el-form
          :model="editForm"
          label-width="150px"
          :rules="rules"
          ref="editVersionForm"
        >
          <el-row>
           
            <el-col :span="8">
                <el-form-item label="IP" prop="ip">
                <el-input
                  v-model="editForm.ip"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="网关" prop="gateway">
                <el-input
                  v-model="editForm.gateway"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          
            <el-col :span="8">
              <el-form-item label="网络掩码" prop="netmask">
                <el-input
                  v-model="editForm.netmask"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="dns1" prop="dns1">
                <el-input
                  v-model="editForm.dns1"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="dns2" prop="dns2">
                <el-input
                  v-model="editForm.dns2"
                  size="small"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
           
          </el-row>
        </el-form>
      </div>
    </div>
  </template>
  
  <script>
  import CustomDialog from "../../components/common/CustomDialog.vue";
  import config from "../../api/config";
  //   import {equipType} from "../../../config/dataStatus"
  //   import versionMange from "../../../api/modules/versionMange";
  import basic from "../../api/modules/basic";
  export default {
    name: "driverEdit",
    components: { CustomDialog },
    props: ["visible", "title", "item", "deviceId"],
    data() {
      return {
        editForm: {},
        rules: {
            IP: [
            { required: true, message: "请输入ip", trigger: "blur" },
          ],
         
          netmask: [{ required: true, message: "请输入网络掩码", trigger: "blur" }],
  
          gateway: [
            { required: true, message: "请输入网关", trigger: "blur" },
          ],
          dns1: [
            { required: true, message: "请输入dns1", trigger: "blur" },
          ],
          dns2: [
            { required: true, message: "请输入dns2", trigger: "blur" },
          ],
          maxload: [
            { required: true, message: "请输入最大调重", trigger: "blur" },
          ],
          rate: [{ required: true, message: "请输入倍率", trigger: "blur" }],
          baseheight: [
            { required: true, message: "请输入基准平面高度差", trigger: "blur" },
          ],
          clamp: [
            { required: true, message: "请输入顶升爬架高度", trigger: "blur" },
          ],
          craneheight: [
            { required: true, message: "请输入塔高", trigger: "blur" },
          ],
          rail: [{ required: true, message: "请输入轨道长度", trigger: "blur" }],
          maxmoment: [
            { required: true, message: "请输入最大力矩", trigger: "blur" },
          ],
        },
        uploadUrl: config.uploadUrl,
        equipType: [],
        params: {},
        equipNo: "",
        typeList: [
          { label: "平臂式", value: "flat-top" },
          { label: "动臂式", value: "luffingjib" },
        ],
        heightzeroList: [
          { label: "顶点", value: "top" },
          { label: "底部", value: "bottom" },
        ],
        paramType:''
        // equipType: equipType
      };
    },
  
    mounted() {
      this.params = JSON.parse(this.item.paramContent);
  
    
  this.paramType=this.item.paramType
      if (this.params) {
        this.editForm = {
          ...this.editForm,
          ...this.params.network,
        };
      }
    },
    methods: {
      colseDialog() {
        this.$emit("close");
      },
      submit() {
        this.$refs.editVersionForm.validate((valid) => {
          if (valid) {
            const obj = {
                network: { ...this.editForm },
            };
            let params = {
              paramContent: JSON.stringify(obj),
              paramName: this.item.paramName,
              paramId: this.item.paramId,
              deviceId: this.deviceId,
              deviceParamDetailId: this.item.deviceParamDetailId,
              paramLable: this.item.paramLable,
              paramType:this.paramType
              // id:props.item.id,
            };
            basic.updateDeviceParam(params).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.$emit("done");
            });
          }
        });
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .editTitle {
    border: 1px solid #eeeeee;
    border-bottom: 0px solid #eeeeee;
    padding: 10px 0 10px 30px;
    border-radius: 6px 6px 0px 0px;
    font-weight: bold;
  }
  .edit-main {
    padding: 20px;
    border: 1px solid #eeeeee;
    border-radius: 0px 0px 6px 6px;
  }
  </style>
  