<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
   
    width="75%"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item) in paneList"
        :key="item.paramLable"
        :label="item.paramName"
        :name="item.paramLable"
        >
        <component :is="parameterTYpeObj[item.paramId]"
             v-if="item.paramName"
            :ref="item.paramLable"
            :item="item"
            :deviceId="deviceId"
            :deviceNo="deviceNo"
            @done="doneDialog"
             >
  </component>
        </el-tab-pane
      >
    </el-tabs>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import workSet from './workSet.vue'
import calibrationSet from './calibrationSet.vue'
import torque from './torque.vue'
import alarm from './alarm.vue'
import anti from './anti.vue'
import pbstacle from './pbstacle.vue'
import camera from './camera.vue'
import others from './others.vue'
import basic from '../../api/modules/basic'
import fourtorque from './fourtorque.vue'
import network from './network.vue'
export default {
  name: "termiParamEdit",
  components: { CustomDialog,workSet ,calibrationSet,torque,alarm,anti,pbstacle,camera,others,fourtorque,network},
  props: ["visible", "title",'item'],
  data() {
    return {
        parameterTYpeObj:{
        1:'workSet',
        2: 'calibrationSet',
        3: 'torque',
        4: 'alarm',
        5: 'anti',
        6: 'pbstacle',
        7: 'camera',
        8: 'others',
        9:'network',
        10:'fourtorque'
      },
      activeName: "workset",
      paneList: [
        // { label: "工况设置", name: "workset" ,id:1},
        // { label: "标定参数", name: "calibration",id:2 },
        // { label: "倍率力矩表", name: "torque" ,id:3},
        // { label: "告警设置", name: "alarm",id:4 },
        // { label: "防碰撞参数", name: "anti" ,id:5},
        // { label: "障碍物设置", name: "pbstacle" ,id:6},
        // { label: "摄像头设置", name: "camera" ,id:7},
        // { label: "其他通用设置", name: "others" ,id:8},
      ],
      deviceId:'',
      deviceNo:''
    };
  },
  watch:{
    item(val){
      console.log('va11l',val);
      this.getdeviceParam(val.deviceId)
      this.deviceId=val.deviceId
      this.deviceNo=val.deviceNo
    }
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    doneDialog(){
      this.$emit("done");
    },
    getdeviceParam(val){
      basic.deviceParam(val).then(res=>{
     
        this.paneList=res.data
        
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    submit() {
    
      
      if (this.activeName === "workset") {
    this.$refs.workset[0].submit();
  } else if (this.activeName === "calibration") {
    this.$refs.calibration[0].submit();
  } else if (this.activeName === "moment") {
    this.$refs.moment[0].submit();
  } else if (this.activeName === "alarm") {
    this.$refs.alarm[0].submit();
  } else if (this.activeName === "collide") {
    this.$refs.collide[0].submit();
  } else if (this.activeName === "obstacle") {
    this.$refs.obstacle[0].submit();
  } else if (this.activeName === "generic") {
    this.$refs.generic[0].submit();
  } else if (this.activeName === "video") {
    this.$refs.video[0].submit();
  } else if (this.activeName === "moment4") {
    this.$refs.moment4[0].submit();
  } else if (this.activeName === "network") {
    this.$refs.network[0].submit();
  }  else if (this.activeName === "pbstacle") {
    this.$refs.pbstacle[0].submit();
  } 
     
    
      
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
</style>
