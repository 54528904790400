<template>
    <div>
      <div class="editTitle">倍率力矩表</div>
  
      <div class="edit-main">
        <el-row>
          <el-col :span="24" class="marginRight">
            <el-button
                  type="success"
                  size="small"
                 @click="travelClick"
                  icon="el-icon-plus"
                 
                  class="btn"
                  >新建</el-button
                >
                <div>2倍力矩表</div>
            <el-table :data="tableData" border style="width: 100%"  >
              <el-table-column prop="luffing" label="变幅" align="center" >
                <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.luffing"
                  placeholder="请输入变幅"
                ></el-input>
              </template>
              </el-table-column>
              <el-table-column prop="name" label="吊重" align="center">
                <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.load"
                  placeholder="请输入吊重"
                ></el-input>
              </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px"> 
                  <template slot-scope="scope">
                <span
                  @click="detailItemTravel(scope.$index)"
                  class="detail"
                >
                <i class="el-icon-delete"></i>
              </span>
            
              </template>
              </el-table-column>
            </el-table>
          </el-col>
          <!-- <el-col :span="24" class="marginRight">
            <el-button
                  type="success"
                  size="small"
                  @click="TwotravelClick"
                  icon="el-icon-plus"
                 
                  class="btn"
                  >新建</el-button
                >
                <div>4倍力矩表</div>
               
            <el-table :data="tableDataTwo" border style="width: 100%">
              <el-table-column prop="date" label="变幅"  align="center">
                <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.luffing"
                  placeholder="请输入变幅"
                ></el-input>
              </template>
              </el-table-column>
              <el-table-column prop="name" label="吊重"  align="center">
                <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.load"
                  placeholder="请输入吊重"
                ></el-input>
              </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px"> 
                  <template slot-scope="scope">
                <span
                  @click="detailItemTwo(scope.$index)"
                  class="detail"
                >
                <i class="el-icon-delete"></i>
              </span>
            
              </template>
              </el-table-column>
            </el-table>
          </el-col> -->
         
        </el-row>
      </div>
    </div>
  </template>
  
  <script>
  import CustomDialog from "../../components/common/CustomDialog.vue";
  import config from "../../api/config";
  //   import {equipType} from "../../../config/dataStatus"
  //   import versionMange from "../../../api/modules/versionMange";
  import basic from "../../api/modules/basic";
  export default {
    name: "driverEdit",
    components: { CustomDialog },
    props: ["visible", "title",'item','deviceId'],
    data() {
      return {
          tableData:[],
          tableDataTwo:[],
          paramType:''
      };
    },
    mounted() {
    this.params = JSON.parse(this.item.paramContent);

    console.log("params", this.params);
    this.paramType=this.item.paramType
    if (this.params) {
      
      this.tableData= this.params.moment
      this.tableDataTwo= this.params.moment4
     
    }
  },
    methods: {
      colseDialog() {
        this.$emit("close");
      },
      submit() {
        const obj = {
        moment:this.tableData,
        // moment4:this.tableDataTwo
          
          }
          let params = {
            paramContent: JSON.stringify(obj),
            paramName: this.item.paramName,
            paramId: this.item.paramId,
            deviceId: this.deviceId,
            deviceParamDetailId: this.item.deviceParamDetailId,
            paramLable: this.item.paramLable,
            paramType:this.paramType
            // id:props.item.id,
          };
          basic.updateDeviceParam(params).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.$emit("done");
          });
      },
      travelClick(){
        this.tableData.push({
          luffing:'',
          load:''
        })
      },
      TwotravelClick(){
        this.tableDataTwo.push({
          luffing:'',
          load:''
        })
      },
      detailItemTravel(index){
        this.tableData.splice(index,1)
      },
      detailItemTwo(index){
        this.tableDataTwo.splice(index,1)
      }
    },
  };
  </script>
  
  <style scoped lang="less">
  .editTitle {
    border: 1px solid #eeeeee;
    border-bottom: 0px solid #eeeeee;
    padding: 10px 0 10px 30px;
    border-radius: 6px 6px 0px 0px;
    font-weight: bold;
  }
  .edit-main {
    padding: 20px;
    border: 1px solid #eeeeee;
    border-radius: 0px 0px 6px 6px;
  }
  .btn {
      background-color: #008f4d;
      // margin-bottom: 10px;
      // color: #008f4d;
    }
    .marginRight{
    //   margin-right: 30px;
    //   width: 49%;
      margin-bottom: 20px;
    }
  </style>
  