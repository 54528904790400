<template>
  <div>
    <div class="editTitle">障碍物设置</div>

    <div class="edit-main">
      <el-button
        type="success"
        size="small"
        @click="travelClick"
        icon="el-icon-plus"
        class="btn"
        >新建</el-button
      >

      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="name" label="障碍物名称" align="center" width="100px">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.name"
              placeholder="请输入障碍物名称"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="height" label="高度" align="center" width="100px">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.height"
              placeholder="请输入高度"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="坐标" align="center" >
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.graph" :key="index">
              <el-form
          :model="item"
          label-position="right"
        
          ref="editVersionForm"
          class="demo-form-inline"
        >
        <el-form-item label="height" prop="register" >
          <el-input
                class="inputs"
                size="small"
                
                v-model="item.height"
                placeholder="请输入height"
              ></el-input>
            </el-form-item>
            <el-form-item label="luffing" prop="register">
          <el-input
                class="inputs"
                size="small"
                v-model="item.luffing"
                placeholder="请输入luffing"
              ></el-input>
            </el-form-item>
            <el-form-item label="rotation" prop="register">
          <el-input
                class="inputs"
                size="small"
                v-model="item.rotation"
                placeholder="请输入rotation"
              ></el-input>
            </el-form-item>
      </el-form>
              <!-- <el-input
                class="inputBox"
                size="small"
                v-model="item.height"
                placeholder="请输入height"
              ></el-input>
              <el-input
                class="inputBox"
                size="small"
                v-model="item.luffing"
                placeholder="请输入luffing"
              ></el-input>
              <el-input
                class="inputBox"
                size="small"
                v-model="item.rotation"
                placeholder="请输入rotation"
              ></el-input> -->
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="100px">
          <template slot-scope="scope">
            <span @click="detailItemTravel(scope.$index)" class="detail">
              <i class="el-icon-delete"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
//   import {equipType} from "../../../config/dataStatus"
//   import versionMange from "../../../api/modules/versionMange";
import basic from "../../api/modules/basic";
export default {
  name: "driverEdit",
  components: { CustomDialog },
  props: ["visible", "title", "item", "deviceId"],
  data() {
    return {
      tableData: [],
      paramType:''
    };
  },
  mounted() {
    this.params = JSON.parse(this.item.paramContent);

    console.log("params111", this.params);
    this.paramType=this.item.paramType
    if (this.params) {
      this.tableData = this.params.obstacle;
    }
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    submit() {
      const obj = {
       
       obstacle:this.tableData

         }
         let params = {
            paramContent: JSON.stringify(obj),
            paramName: this.item.paramName,
            paramId: this.item.paramId,
            deviceId: this.deviceId,
            deviceParamDetailId: this.item.deviceParamDetailId,
            paramLable: this.item.paramLable,
            paramType:this.paramType
          };
          basic.updateDeviceParam(params).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.$emit("done");
          });
    },
    travelClick() {
      this.tableData.push({
        name: "",
        height: "",
        graph:[{
          rotation:'',
          height:'',
          luffing:'',
        
        }]
      });
    },
    detailItemTravel(index) {
      this.tableData.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
.btn {
  background-color: #008f4d;
  // margin-bottom: 10px;
  // color: #008f4d;
}
.marginRight {
  //   margin-right: 30px;
  //   width: 49%;
  margin-bottom: 20px;
}
.inputBox {
  width: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.el-form {
  display: flex;
  // align-items: center;
}
/deep/.inputs .el-input__inner {
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 50%;
}
.el-form-item {
    margin-bottom: 20px;
    display: flex;
}
</style>
