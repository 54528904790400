<template>
  <div>
    <div class="editTitle">摄像头参数</div>

    <div class="edit-main">
      <el-form
        :model="editForm"
        label-width="150px"
        :rules="rules"
        ref="editVersionForm"
        class="demo-form-inline"
      >
        <el-row :gutter="160">
          <el-col :span="24">
            <el-form-item label="设备编号">
              {{ deviceNo }}
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="安装位置：" prop="postion">
              <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.postion"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in postionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目标空间补偿值X：" prop="x">
              <el-input
                v-model="editForm.x"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目标空间补偿值Y：" prop="y">
              <el-input
                v-model="editForm.y"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目标空间补偿值Z：" prop="z">
              <el-input
                v-model="editForm.z"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="吊钩IP：" prop="hookip">
              <el-input
                v-model="editForm.hookip"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="远程播放地址：" prop="hookip">
              <el-input
                v-model="editForm.hookip"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="卷扬机IP：" prop="winchip">
              <el-input
                v-model="editForm.winchip"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="远程播放地址：" prop="winchip">
              <el-input
                v-model="editForm.winchip"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="顶升IP：" prop="jackip">
              <el-input
                v-model="editForm.jackip"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="远程播放地址：" prop="jackip">
              <el-input
                v-model="editForm.jackip"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="吊钩偏移量：" >
              {{ 115651 }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分辨率：" >
              {{ 11111 }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="垂直补偿50m：" prop="H50">
              <el-input
                v-model="editForm.hookcomp.H50"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="水平补偿：" prop="X">
              <el-input
                v-model="editForm.hookcomp.X"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="垂直补偿100m：" prop="H100">
              <el-input
                v-model="editForm.hookcomp.H100"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="垂直补偿150m：" prop="H150">
              <el-input
                v-model="editForm.hookcomp.H150"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="垂直补偿200m：" prop="H200">
              <el-input
                v-model="editForm.hookcomp.H200"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="垂直补偿250m：" prop="H250">
              <el-input
                v-model="editForm.hookcomp.H250"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="垂直补偿300m：" prop="H300">
              <el-input
                v-model="editForm.hookcomp.H300"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
//   import {equipType} from "../../../config/dataStatus"
//   import versionMange from "../../../api/modules/versionMange";
import basic from "../../api/modules/basic";
export default {
  name: "driverEdit",
  components: { CustomDialog },
  props: ["visible", "title", "item", "deviceId",'deviceNo'],
  data() {
    return {
      editForm: {
        postion: "",
        x: "",
        y: "",
        z: "",
        hookip: "",
        winchip: "",
        jackip: "",
        hookcomp: {
          H50: "",
          H100: "",
          H150: "",
          H200: "",
          H250: "",
          H300: "",
          X: "",
        },
      },
      rules: {
        x: [{ required: true, message: "请输入X坐标", trigger: "blur" }],
        y: [{ required: true, message: "请输入y坐标", trigger: "blur" }],
        z: [{ required: true, message: "请输入z坐标", trigger: "blur" }],
        postion: [
          { required: true, message: "请选择安装位置", trigger: "change" },
        ],
        hookip: [{ required: true, message: "请输入吊钩IP", trigger: "blur" }],
       
        winchip: [
          { required: true, message: "请输入卷扬机IP", trigger: "blur" },
        ],
        jackip: [
          { required: true, message: "请输入顶升IP", trigger: "blur" },
        ],
        // H50: [
        //   { required: true, message: "请输入垂直补偿50m", trigger: "blur" },
        // ],
        // H100: [
        //   { required: true, message: "请输入垂直补偿100m", trigger: "blur" },
        // ],
        // H150: [
        //   { required: true, message: "请输入垂直补偿150m", trigger: "blur" },
        // ],
        // H200: [
        //   { required: true, message: "请输入垂直补偿200m", trigger: "blur" },
        // ],
        // H250: [
        //   { required: true, message: "请输入垂直补偿250m", trigger: "blur" },
        // ],
        // H300: [
        //   { required: true, message: "请输入垂直补偿300m", trigger: "blur" },
        // ],
        // X: [
        //   { required: true, message: "请输入水平补偿", trigger: "blur" },
        // ],
      },
      uploadUrl: config.uploadUrl,
      postionList: [
        { label: "头部", value: "head" },
        { label: "小车", value: "gantry" },
        { label: "回转中心", value: "rotation" },
      ],
      paramType:''
      // equipType: equipType
    };
  },
  mounted() {
    this.params = JSON.parse(this.item.paramContent);

    console.log("this.params", this.params);
    this.paramType=this.item.paramType
    if (this.params) {
      this.editForm = {
        ...this.editForm,
        ...this.params.video,
      };
    }
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    submit() {
      this.$refs.editVersionForm.validate((valid) => {
        if (valid) {
          const obj = {
            video: { ...this.editForm },
          };
          let params = {
            paramContent: JSON.stringify(obj),
            paramName: this.item.paramName,
            paramId: this.item.paramId,
            deviceId: this.deviceId,
            deviceParamDetailId: this.item.deviceParamDetailId,
            paramLable: this.item.paramLable,
            paramType:this.paramType
          };
          basic.updateDeviceParam(params).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.$emit("done");
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
.smallInput {
  width: 80px;
}
</style>
