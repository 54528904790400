import { render, staticRenderFns } from "./anti.vue?vue&type=template&id=418c34b9&scoped=true"
import script from "./anti.vue?vue&type=script&lang=js"
export * from "./anti.vue?vue&type=script&lang=js"
import style0 from "./anti.vue?vue&type=style&index=0&id=418c34b9&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "418c34b9",
  null
  
)

export default component.exports