<template>
  <div>
    <div class="editTitle">塔群防碰撞</div>

    <div class="edit-main">
      <el-button
        type="success"
        size="small"
        @click="travelClick"
        icon="el-icon-plus"
        class="btn"
        >新建</el-button
      >

      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="fieldnum" label="标识编号" align="center">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.fieldnum"
              placeholder="请输入标识编号"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="duid" label="现场名称" align="center">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.duid"
              placeholder="请输入现场名称"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="x" label="X坐标" align="center">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.x"
              placeholder="请输入X坐标"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="y" label="Y坐标" align="center">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.y"
              placeholder="请输入Y坐标"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span @click="detailItemTravel(scope.$index)" class="detail">
              <i class="el-icon-delete"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
//   import {equipType} from "../../../config/dataStatus"
//   import versionMange from "../../../api/modules/versionMange";
import basic from "../../api/modules/basic";
export default {
  name: "driverEdit",
  components: { CustomDialog },
  props: ["visible", "title", "item", "deviceId"],
  data() {
    return {
      tableData: [],
      paramType:''
    };
  },
  mounted() {
    this.params = JSON.parse(this.item.paramContent);

    console.log("params", this.params);
    this.paramType=this.item.paramType
    if (this.params) {
      this.tableData = this.params.collide;
    }
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    submit() {
      console.log('11');
      
      const obj = {
       
       collide:this.tableData

         }
          let params = {
            paramContent: JSON.stringify(obj),
            paramName: this.item.paramName,
            paramId: this.item.paramId,
            deviceId: this.deviceId,
            deviceParamDetailId: this.item.deviceParamDetailId,
            paramLable: this.item.paramLable,
            paramType:this.paramType
          };
          basic.updateDeviceParam(params).then((res) => {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.$emit("done");
          });
    },
    travelClick() {
      this.tableData.push({
        fieldnum: "",
        duid: "",
        x: "",
        y: "",
      });
    },
    detailItemTravel(index) {
      this.tableData.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
.btn {
  background-color: #008f4d;
  // margin-bottom: 10px;
  // color: #008f4d;
}
.marginRight {
  //   margin-right: 30px;
  //   width: 49%;
  margin-bottom: 20px;
}
</style>
